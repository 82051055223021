import type { LocationRoleType } from "@prisma/client";
import { Role } from "@prisma/client";
import { assertAuthorized } from "./assert.utils.server";
import {
  RVILocationRolePermissionMap,
  type PermissionType,
  type RVIPermissionUser,
} from "./intelligence-permission.utils";
import { userCanDo } from "./permissions.utils";
import { redirect } from "@remix-run/node";

export function canDoOnLocation(
  user: RVIPermissionUser,
  account: { id: string },
  location: { id: string },
  permission: PermissionType | PermissionType[]
): boolean {
  if (!account) return false;
  if (!location) return false;
  if (user.user_roles.some(role => role.role === Role.GOD_MODE)) return true;

  const managerAccountRole = user.manager_account_roles.find(
    role => role.manager_account_id === account.id
  );

  // If no role for this account, return false
  if (!managerAccountRole) return false;

  const locationRole = managerAccountRole.location_roles.find(
    role => role.location_id === location.id
  );

  // If no role for this location, check on account
  if (!locationRole) return userCanDo(user, permission, account);

  const permissions = RVILocationRolePermissionMap[locationRole.type];

  if (permission instanceof Array) {
    const notAuthorizedPermissions = permission.filter(
      p => !permissions.includes(p)
    );

    return (
      notAuthorizedPermissions.length === 0 ||
      // If any permission is not authorized on the location role, check on account
      userCanDo(user, notAuthorizedPermissions, account)
    );
  } else {
    return (
      permissions.includes(permission) || userCanDo(user, permission, account)
    );
  }
}

export function canDoOnLocationOrThrow(
  user: RVIPermissionUser,
  account: { id: string },
  location: { id: string },
  permission: PermissionType | PermissionType[],
  fallback?: string
): void {
  const authorized = canDoOnLocation(user, account, location, permission);

  if (authorized) return;

  if (fallback) {
    throw redirect(fallback);
  }

  const message = `You are not authorized to ${permission} in this location`;

  assertAuthorized(authorized, message);
}

export function canDoSomeOnLocation(
  user: RVIPermissionUser,
  account: { id: string },
  location: { id: string },
  permissions: PermissionType[]
): boolean {
  return permissions.some(permission =>
    canDoOnLocation(user, account, location, permission)
  );
}

export function canDoSomeOnLocationOrThrow(
  user: RVIPermissionUser,
  account: { id: string },
  location: { id: string },
  permissions: PermissionType[],
  fallback?: string
): void {
  const authorized = canDoSomeOnLocation(user, account, location, permissions);

  if (authorized) return;

  if (fallback) {
    throw redirect(fallback);
  }

  const message = `You are not authorized to go into this location's section`;

  assertAuthorized(authorized, message);
}

/**
 * Returns the list of locations where the user can do the given permission on
 * @returns "all" if the user can do the permission on the account, otherwise an array of location ids
 */
export function getLocationIdsForUser(
  user: RVIPermissionUser,
  account: { id: string },
  permissions: PermissionType[]
): string[] | "all" {
  if (userCanDo(user, permissions, account)) {
    return "all";
  }

  const managerAccountRole = user.manager_account_roles.find(
    role => role.manager_account_id === account.id
  );

  // If no role for this account, return no locations
  if (!managerAccountRole) return [];

  const locationIds = managerAccountRole.location_roles
    .filter(role =>
      permissions.every(permission =>
        RVILocationRolePermissionMap[role.type].includes(permission)
      )
    )
    .map(role => role.location_id);

  return locationIds;
}

/**
 * Check if the user has a role with the specified permission at any location.
 * @returns {boolean} - True if the user has a role with the specified permission, otherwise false.
 */
export function canDoAtAnyLocation(
  user: RVIPermissionUser,
  account: { id: string },
  permissions: PermissionType[]
): boolean {
  if (!account) return false;
  if (user.user_roles.some(role => role.role === Role.GOD_MODE)) return true;

  const managerAccountRole = user.manager_account_roles.find(
    role => role.manager_account_id === account.id
  );

  // If no role for this account, return false
  if (!managerAccountRole) return false;

  const authorized = user.manager_account_roles.some(role =>
    role.location_roles.some(userLocationRole =>
      permissions.every(permission =>
        RVILocationRolePermissionMap[userLocationRole.type].includes(permission)
      )
    )
  );

  const accountAuthorized =
    permissions instanceof Array
      ? permissions.every(permission => userCanDo(user, permission, account))
      : false;

  return authorized || accountAuthorized;
}

export function canDoAtAnyLocationOrThrow(
  user: RVIPermissionUser,
  account: { id: string },
  permissions: PermissionType[],
  fallback?: string
): void {
  const authorized = canDoAtAnyLocation(user, account, permissions);

  if (authorized) return;

  if (fallback) {
    throw redirect(fallback);
  }

  const message = `You are not authorized to go into this Intelligence Account's section`;

  assertAuthorized(authorized, message);
}

/**
 * Filters location roles based on permissions
 * @returns {LocationRoleType[]} - True if the user has a role with the specified permission, otherwise false.
 */
export function filterLocationRolesByPermissions(
  requiredPermissions: PermissionType[]
): LocationRoleType[] {
  return Object.keys(RVILocationRolePermissionMap).filter(role =>
    requiredPermissions.some(permission =>
      RVILocationRolePermissionMap[role as LocationRoleType].includes(permission)
    )
  ) as LocationRoleType[];
}
