import type {
  LocationRoleType,
  ManagerAccountRoleType,
  Role,
  UserRole,
} from "@prisma/client";
import { adminCanDo, type AdminPermissionType } from "./admin-permission.utils";
import {
  canDoOnAccount,
  type PermissionType,
  type RVIPermissionUser,
} from "./intelligence-permission.utils";
import {
  userHasPermission,
  type UserPermission,
} from "./product-subscription-permission.utils";
import type { SerializeFrom } from "@remix-run/node";
import { canDoOnLocation } from "./location-permission.utils";

type ManagerAccountRoleWithLocationRoles = {
  id: string;
  manager_account_id: string;
  role: ManagerAccountRoleType;
  location_roles: {
    location_id: string;
    type: LocationRoleType;
  }[];
};
type User = {
  id?: string;
  manager_account_roles?:
    | ManagerAccountRoleWithLocationRoles[]
    | SerializeFrom<ManagerAccountRoleWithLocationRoles>[];
  user_roles: UserRole[] | SerializeFrom<UserRole>[];
};

export function userCanDo(
  user: User,
  permission:
    | (PermissionType | PermissionType[])
    | (AdminPermissionType[] | AdminPermissionType)
    | (UserPermission[] | UserPermission),
  account?: { id: string } | null,
  location?: { id: string } | null,
  role?: Role
) {
  if (account && location) {
    return canDoOnLocation(
      user as RVIPermissionUser,
      { id: account.id },
      { id: location.id },
      permission as PermissionType | PermissionType[]
    );
  }

  if (account) {
    return canDoOnAccount(
      user as RVIPermissionUser,
      { id: account.id },
      permission as PermissionType | PermissionType[]
    );
  }

  if (role) {
    return userHasPermission(role, permission as UserPermission);
  }

  return adminCanDo(user, permission as AdminPermissionType);
}
